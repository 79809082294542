import { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box,
  Divider,
  Textarea,
  Input,
  VStack,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Progress,
} from '@chakra-ui/react';

import SubQuestForm from 'components/subQuest/SubQuestForm';
import FormikFormControl from 'components/common/forms/FormikFormControl';

import useFileInput from 'hooks/useFileInput';
import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';

import { subquestActions } from 'store/modules/subquest/subquest-slice';
import { updateInteractiveVideoSubQuest } from 'store/modules/subquest/subquest-action';
import { arrayOfString } from 'utils/jsonParser';

function UpdateInteractiveVideoMd() {
  const dispatch = useDispatch();

  const [triggerSubQuestForm, setTriggerSubQuestForm] = useState(0);
  const [isSubQuestFormValid, setIsSubQuestFormValid] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [subQuestFormInputs, setSubQuestFormInputs] = useState({
    name: '',
    objective: '',
    code: '',
    isPublished: '',
    tags: [],
    groups: [],
  });

  const [formInputs, setFormInputs] = useState({
    transcript: '',
    indexName: 'index_lms.html',
  });

  const subQuest = useSelector((state) => state.subquest.subQuest);
  const questId = useSelector((state) => state.subquest.questId);
  const updateInteractiveVideo = useSelector(
    (state) => state.subquest.updateInteractiveVideo
  );

  const uploadProgress = useSelector((state) => state.subquest.uploadProgress);

  const formikForm = useRef();

  const {
    file,
    fileError,
    fileInputRef,
    setFileError,
    handleAttachFile,
    handleRemoveSelectedFile,
    handleFileInputChange,
  } = useFileInput({ maxFileSizeMb: 850, fileType: 'application/' });

  useEffect(() => {
    if (subQuest && updateInteractiveVideo.modal) {
      const { name, objective, code, isPublished, tags, ownerGroups } =
        subQuest;
      const { indexName, transcript } = subQuest.subQuestInteractiveVideo;

      setIsSubQuestFormValid(false);
      setSubQuestFormInputs({
        name,
        objective,
        code,
        isPublished: isPublished.toString(),
        tags: arrayOfString(tags),
        groups: arrayOfString(ownerGroups),
      });

      setFormInputs({
        transcript,
        indexName,
      });
    }
  }, [
    subQuest,
    updateInteractiveVideo.modal,
    setSubQuestFormInputs,
    setFormInputs,
    setIsSubQuestFormValid,
  ]);

  useEffect(() => {
    if (file.name) setFileError('');
  }, [file, setFileError]);

  const formValidation = Yup.object({
    transcript: Yup.string().min(3).required('Required'),
    indexName: Yup.string().min(1).max(20).required('Required'),
  });

  const handleTriggerSubmit = () => {
    setTriggerSubQuestForm((trigger) => trigger + 1);
    setIsFormSubmit(true);
  };

  const handleSubmitForm = (values) => {
    if (isSubQuestFormValid === false) return;

    setFormInputs({ ...values });
    const formData = new FormData();

    Object.entries(subQuestFormInputs).forEach(([key, value]) => {
      if (key !== 'tags' && key !== 'groups') formData.append(key, value);
    });

    if (subQuestFormInputs.tags.length > 0)
      subQuestFormInputs.tags.forEach((tag) => formData.append('Tags', tag));
    if (subQuestFormInputs.groups.length > 0)
      subQuestFormInputs.groups.forEach((group) =>
        formData.append('OwnerGroups', group)
      );

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (file.name) {
      formData.append('ZipFile', file, file.name);
      formData.append('IsRemoveFile', 'true');
    }

    dispatch(
      updateInteractiveVideoSubQuest({
        subQuestId: subQuest?.id,
        questId,
        formData,
      })
    );
  };

  useEffect(() => {
    if (isFormSubmit && isSubQuestFormValid) {
      formikForm?.current?.submitForm();
      setIsFormSubmit(false);
    }
  }, [isFormSubmit, isSubQuestFormValid, setIsFormSubmit]);

  const handleToggleUpdateModal = (isOpen) => {
    dispatch(subquestActions.toggleInteractiveVideoUpdateModal(isOpen));
  };

  const handleResult = () => {
    if (updateInteractiveVideo.status === ReducerStatus.SUCCEEDED) {
      setFormInputs({
        transcript: '',
        indexName: 'index_lms.html',
      });

      setSubQuestFormInputs({
        name: '',
        objective: '',
        code: '',
        isPublished: '',
        tags: [],
        emails: [],
      });

      setTriggerSubQuestForm(0);
      setIsSubQuestFormValid(false);
      handleRemoveSelectedFile();
      handleToggleUpdateModal(true);
    }
    dispatch(subquestActions.resetInteractiveVideoUpdate());
  };

  return (
    <Box>
      {updateInteractiveVideo.status === ReducerStatus.SUCCEEDED ||
      updateInteractiveVideo.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={updateInteractiveVideo.modal}
          onSubmit={handleResult}
          isError={updateInteractiveVideo.status === ReducerStatus.FAILED}
          title={
            updateInteractiveVideo.status === ReducerStatus.SUCCEEDED
              ? 'Interactive Video Sub-Quest Updated'
              : null
          }
          message={
            updateInteractiveVideo.status === ReducerStatus.SUCCEEDED
              ? 'The interactive video sub-quest has been updated successfully. Please allow a few minutes for the system to unzip the attached files if you have updated the content.'
              : updateInteractiveVideo.error?.data?.title || 'Server Error'
          }
          errorObject={updateInteractiveVideo.error?.data?.errors}
        />
      ) : (
        <Modal
          size="2xl"
          isOpen={updateInteractiveVideo.modal}
          closeOnOverlayClick={
            updateInteractiveVideo.status !== ReducerStatus.LOADING
          }
          onClose={() => {
            handleToggleUpdateModal(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Interactive Video Sub-Quest</ModalHeader>
            <ModalCloseButton
              disabled={updateInteractiveVideo.status === ReducerStatus.LOADING}
            />
            <ModalBody my={3}>
              <SubQuestForm
                subQuestFormInputs={subQuestFormInputs}
                triggerSubQuestForm={triggerSubQuestForm}
                setSubQuestFormInputs={setSubQuestFormInputs}
                setIsSubQuestFormValid={setIsSubQuestFormValid}
              />
            </ModalBody>
            <Formik
              enableReinitialize
              innerRef={formikForm}
              initialValues={formInputs}
              onSubmit={(values) => {
                handleSubmitForm(values);
              }}
              validationSchema={formValidation}
            >
              {() => (
                <Box>
                  <Form>
                    <ModalBody>
                      <Divider mb={4} />

                      <VStack spacing={6} align="stretch">
                        <FormControl isInvalid={fileError !== ''}>
                          {file?.name && (
                            <Box my={4}>
                              <Flex
                                gap={2}
                                my={2}
                                justify="center"
                                align="center"
                              >
                                <Text flex={1}>Current File: {file?.name}</Text>
                                <Button
                                  type="button"
                                  colorScheme="teal"
                                  variant="ghost"
                                  onClick={handleRemoveSelectedFile}
                                >
                                  <CloseIcon />
                                </Button>
                              </Flex>
                            </Box>
                          )}

                          <Text fontSize="sm" as="b">
                            Ensure that the attached zip file contains only one
                            folder when opened (excluding the default _MACOSX
                            folder on macOS)
                          </Text>

                          <Box
                            as="button"
                            type="button"
                            borderRadius="md"
                            bg="teal"
                            w="100%"
                            px={4}
                            mt={5}
                            h={10}
                            color="white"
                            onClick={handleAttachFile}
                          >
                            <input
                              type="file"
                              hidden
                              accept="application/zip"
                              ref={fileInputRef}
                              onChange={handleFileInputChange}
                            />
                            <AttachmentIcon mx={2} />
                            Attach Interactive Video File
                          </Box>
                          {fileError !== '' && (
                            <FormErrorMessage>{fileError}</FormErrorMessage>
                          )}
                          {!file.name && (
                            <Text mt={2}>
                              Current File: &nbsp;
                              {subQuest?.subQuestInteractiveVideo?.filePath}
                            </Text>
                          )}

                          <FormHelperText>
                            Please attach the interactive video file in a
                            compressed state (.zip).
                          </FormHelperText>
                        </FormControl>

                        <Field name="indexName">
                          {({ field, form }) => (
                            <FormikFormControl
                              label="Index Name"
                              helperText="Please specify the root path .html of the zipped file for the interactive content. Typically, the root path is index_lms.html."
                              errors={form.errors.indexName}
                              touched={form.touched.indexName}
                            >
                              <Input {...field} />
                            </FormikFormControl>
                          )}
                        </Field>

                        <Field name="transcript">
                          {({ field, form }) => (
                            <FormikFormControl
                              label="Transcript"
                              helperText="Please provide the transcript of the video. The transcript will be utilized to train the AI Companion, enabling it to respond to trainee questions that are relevant to the content covered in the video."
                              errors={form.errors.transcript}
                              touched={form.touched.transcript}
                            >
                              <Textarea {...field} />
                            </FormikFormControl>
                          )}
                        </Field>

                        {updateInteractiveVideo.status ===
                          ReducerStatus.LOADING && (
                          <Progress
                            hasStripe
                            isAnimated
                            value={uploadProgress}
                          />
                        )}
                      </VStack>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        variant="outline"
                        colorScheme="blue"
                        mr={3}
                        isLoading={
                          updateInteractiveVideo.status ===
                          ReducerStatus.LOADING
                        }
                        onClick={() => {
                          handleToggleUpdateModal(false);
                        }}
                      >
                        Close
                      </Button>
                      <Button
                        isLoading={
                          updateInteractiveVideo.status ===
                          ReducerStatus.LOADING
                        }
                        onClick={handleTriggerSubmit}
                        colorScheme="red"
                        px={10}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                </Box>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default UpdateInteractiveVideoMd;
